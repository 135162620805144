import React from 'react';
import './App.css';
import {Widget, addResponseMessage, addLinkSnippet, addUserMessage} from 'react-chat-widget';
import logoUser from './logo.svg';
import {Strophe, $build, $iq, $msg, $pres} from 'strophe.js'
import atomic from 'atomicjs';
import 'strophejs-plugin-muc';
import  trackResaData  from './tracking/script_gtm.js';

function App() {

    const hotel_ref = arguments[0].hotel_ref;

    /*** Pensez a voir si besoin d'identifier un visiteur par dela les sessions et cross hotels***/

    const widgetcustomization_url = "https://beta.guestinbox.com:4242/getwidgetdata?jid=";
    const joinroom_url = "https://beta.guestinbox.com:4242/createroom?jid="
    const server = 'hotel3.guestinbox.com';
    const BOSH_SERVICE = 'https://hotel3.guestinbox.com:5285/bosh/';
    let connection = null; // initializing connection with NULL
    const Debugprog = false;
    var startTime = null;

    var getIds = () => {
        logC('someone is getting the IDS');
        logC(sessionStorage.getItem('strophe-bosh-session'));
        return JSON.parse(sessionStorage.getItem('strophe-bosh-session'));
    }

    var logC = (msg) => {
        if (Debugprog) console.log(msg);
    }

    connection = new Strophe.Connection(BOSH_SERVICE, {'keepalive': true});
    connection.rawInput = function (data) {
        logC('RECV: ' + data);
    };
    connection.rawOutput = function (data) {
        logC('SENT: ' + data);
    };

    async function connectToVhost(vhost, handler) {
        return connection.connect(vhost, null, handler);
    }

    async function establishConnection(connection) {
        try {
            connection.restore(null, onConnect);
            return connection;
        } catch (e) {
            logC('Only connecting, no restore');
            logC(e.name);
            if (e.name !== "StropheSessionError") {
                throw(e);
            }
            await connectToVhost('hotel3.guestinbox.com', onConnect)
            return connection;
        }
    }

    async function waitForconnection(connection) {
        await establishConnection(connection)
    }

    waitForconnection(connection);

    function onConnect(status) {
        if (status === Strophe.Status.CONNECTING) {
            logC('connecting..');
        } else if (status === Strophe.Status.CONNFAIL) {
            logC('failed to connect.');
        } else if (status === Strophe.Status.DISCONNECTING) {
            logC('disconnecting..');
        } else if (status === Strophe.Status.DISCONNECTED) {
            logC('disconnected.');
        } else if (status === Strophe.Status.CONNECTED) {
            logC('connected. :' + connection.jid);
            let tt = getCustomData(connection.jid);
            afterOnline(connection);
        } else if (status === Strophe.Status.ATTACHED) {
            logC("Connection restored");
            afterOnline(connection);
        }
    }


    function afterOnline(connection) {
        logC("in AfterOnline");

        let jid = connection.jid;
        logC(jid);
        connection.send($pres());
        //connection.addHandler(onMessage, null, 'message', null, null, null);
        connection.addHandler(onSubscriptionRequest, null, "presence", "subscribe");
        let hotelInfos = getCustomData(jid);
        logC(connection);
        connection.muc.init(connection);
        /**** In this case the session is restored and a previous chat have been initialized before ****/
        if (connection.restored && sessionStorage.getItem('hdbRoomId')) {
            enterRoom(sessionStorage.getItem('hdbRoomId'), jid);
        }

        // connection.addHandler(onPresence, null, "presence");
        //postBookingData(jid);
        trackResaData(jid, hotel_ref, hotelInfos);
    }

    function handleNewUserMessage(newMessage) {
        logC(`New message sent! ${newMessage}`);
        // Now send the message throught the backend API
        sendMessage(newMessage);
    }

    async function sendMessage(msg) {
        let {jid, rid, sid} = getIds();
        let reply;
        logC(jid);
        let room_id = sessionStorage.getItem('hdbRoomId');
        if (!room_id) await goIntoRoom(jid)
            .then(response => {
                logC(response);
                room_id = response.data.rjid;
                sessionStorage.setItem('hdbRoomId', room_id);
                logC('visitor ' + jid + ' join room ' + room_id);
                logC(room_id);
                return room_id;
            })
            .then(rjid => {
                enterRoom(rjid, jid);
                logC('SendMessage after create room and join room: Send a message to ' + rjid + ': ' + msg);
                connection.muc.groupchat(room_id, msg);
            });
        else {
            logC('regular send');
            logC(room_id);
            logC(jid);
            connection.muc.groupchat(room_id, msg);
        }
    }

    function onMessage(message) {
        return true;
    }


    function onSubscriptionRequest(stanza) {
        let {jid, rid, sid} = getIds();
        if (stanza.getAttribute("type") == "subscribe") {
            let from = jid;
            logC('onSubscriptionRequest: from=' + from);
            // Send a 'subscribed' notification back to accept the incoming
            // subscription request
            connection.send($pres({
                to: from,
                type: "subscribed"
            }));
        }
        return true;
    }

    function enterRoom(room, jid) {
        logC("enterRoom: " + room + ", " + jid);
        //connection.muc.join(room, jid, room_msg_handler, room_pres_handler);
        connection.muc.join(room, jid, room_msg_handler, room_pres_handler, null, null, {
            maxstanzas: 100,
            seconds: 36000
        });
        //connection.muc.setStatus(room, $('#jid').get(0).value, 'subscribed', 'chat');
    }

    function room_msg_handler(a, b, c) {
        logC('in onmessage room_msg_handler');
        logC("A");
        logC(a);
        let message = a;
        logC("B");
        logC(b);
        const {jid, rid, sid} = getIds();
        const to = message.getAttribute('to');
        const from = message.getAttribute('from');
        const type = message.getAttribute('type');
        const elems = message.getElementsByTagName('body');
        const archives = message.getElementsByTagName('delay'); 
        

        if (from.search(to) !== -1 && archives.length === 0) return true;
        if (type == "groupchat" && elems.length > 0) {
            var body = elems[0];
            var room = Strophe.unescapeNode(Strophe.getNodeFromJid(from));
            var nick = Strophe.getResourceFromJid(from);
            logC('GROUP CHAT: I got a message from ' + from + ': ' + Strophe.getText(body) + ' in room: ' + room + ' dest: ' + to);
            from.search(to) !== -1 ? addUserMessage(Strophe.getText(body)) : addResponseMessage(Strophe.getText(body));
        }
        return true;
    }

    function room_pres_handler(a, b, c) {
        logC('MUC: room_pres_handler');
        logC("Pres-A");
        logC(a);
        logC("Pres-B");
        logC(b);
        logC("Pres-C");
        logC(c);

        return true;
    }

    function setCookie(cname, cvalue) {
        document.cookie = cname + "=" + cvalue + "; " + '; Path=/;';
    }

    function getCookie(cname) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + cname + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

    function postBookingData(jid) {
        let hdb_url = 'https://beta.guestinbox.com:4242/booking/availprobooking';
        window.addEventListener('message', function (e) {
            let task = e.data['task']; // task received in postMessage
            let payload = JSON.parse(e.data['payload']);
            payload.hdb_jid = jid;
            switch (task) { // postMessage tasks
                case 'pushBookingConfirmation':
                    let xhr = new XMLHttpRequest();
                    xhr.open('POST', hdb_url);
                    logC(payload);
                    xhr.send(JSON.stringify(payload));
                    logC('Booking confirmation data collected and sent');

                    break;

                case 'val':
                    // Perform 'val' tasks here
                    break;

                // more tasks and code ..
            }
        });
    }

    async function goIntoRoom(jid) {

        return atomic(joinroom_url + encodeURI(jid)+"&channel=WEB")
            .then(data => data);
    }

    async function getCustomData(jid) {
        return atomic(widgetcustomization_url + encodeURI(jid))
            .then(data => JSON.parse(data))
            .catch(function (error) {
                logC(error.status); // xhr.status
                logC(error.statusText); // xhr.statusText
                return null;
            });
    }

    return (
        <div className="App">
            <Widget
                handleNewUserMessage={handleNewUserMessage}
                profileAvatar={logoUser}
                title="My new awesome title"
                subtitle="And my cool subtitle"
            />
        </div>
    );
}

export default App;
