import atomic from 'atomicjs';
import geoip2 from './geotracking.js'

function trackResaData(jid, hotel_ref, hotelInfos) {
    sessionStorage.setItem('hotelName', hotelInfos.name);
    sessionStorage.setItem('hotelRef', hotel_ref);
    const trackingResaUrl = "https://beta.guestinbox.com:4242/visitortrack"; 
    let level = 1,
        user_ip, referrer = 0,
        multiM = 0,
        chain_ref = null;

        if (window.dataLayer ) {
            let compteurEvent = dataLayer.length;
            for (let i = 0; i < compteurEvent; i++) {
                let dataT = dataLayer[i];
                if (dataT.bookingStep == 'HotelSelection') {
                    chain_ref = hotel_ref;
                    level = 2;
                    multiM = 1;
                    break;
                } else {
                    if (dataT.context != undefined && dataT.context.bookingEngineReference != hotel_ref) {
                        hotel_ref = dataT.context.hotelId;
                    } else {
                        hotel_ref = attribut[1];
                    }
                    level = 1;
                    multiM = 0;
                    break;
                }
            }
        }

    let keys = new Array('hotelRef', 'hotelName', 'checkinDate', 'checkoutDate', 'currency', 'nights', 'roomId', 'roomName', 'totalPrice', 'title', 'firstName', 'lastName', 'country', 'email', 'phoneNumber');
    let labels = new Array('Hotel Ref', 'Hotel Name', 'Checkin Date', 'Checkout Date', 'Currency', 'Nights', 'Room ID', 'Room Name', 'Price', 'Title', 'First Name', 'Last Name', 'Country', 'Email', 'Phone');

    var oldBookingData = {};
    var hdb_booking_data = {};
    const visitorData = trackingVisitor();
    var timeWebSite = 0;
    var visitedPages = 0;
    var currentPageAddress = '';
    var pagesV = [];

    setInterval(function () {
        timeWebSite = getTimeOnWebSite();
        visitedPages = getAnyVisitedPages(pagesV);
        currentPageAddress = getCurrentPageaddress();

    },300);

    setInterval(function () {
        fnGetChatBookingDataTag();
        hdb_booking_data = {};
        for (var i = 0; i < keys.length; i++) {
            if (sessionStorage.getItem(keys[i]) !== 'null') {
                var obj = {};
                obj.name = labels[i];
                obj.value = sessionStorage.getItem(keys[i]);
                hdb_booking_data[keys[i]] = sessionStorage.getItem(keys[i]);
            }
        }
        if (JSON.stringify(oldBookingData) !== JSON.stringify(hdb_booking_data)) {
            oldBookingData = hdb_booking_data;
            if (sessionStorage.length > 0) {
                if (multiM != 1) {
                    atomic(trackingResaUrl,{
                        method: 'POST',
                        data: {
                            hdb_jid: jid,
                            user_ip: user_ip,
                            level: level,
                            chain_ref: chain_ref,
                            referrer: referrer,
                            booking_data: hdb_booking_data,
                            browser_data: visitorData.visitor.browser,
                            geolocData: visitorData.visitor.geoloc,
                            timeonwebsite : timeWebSite,
                            anyvisitedpages : visitedPages,
                            currentpageaddress : currentPageAddress,
                        },
                        headers: {
                            'Content-type': 'application/json'
                        },

                    });
                }
            }
        }

    }, 3000);
/*
* {
  "name": "Hotel 3",
  "address": "adresse",
  "room_number": 1,
  "country": "Afghanistan",
  "host_prefix": "hotel3.guestinbox.com",
  "hotel_ref": "G-16789",
  "config": "{\"ecolor\": \"040506\", \"scolor\": \"010203\"}"
}
*
* */
    function fnGetChatBookingDataTag() {
        try {
            var compteur = dataLayer.length;
            for (var c = 0; c < compteur; c++) {

                if (dataLayer[c].event == 'VirtualPageView') {
                    if (dataLayer[c].context.bookingEngineReference != null || dataLayer[c].context.engineReference != null) {
                        if (dataLayer[c].context.searchParameters != null) {
                            sessionStorage.setItem('checkinDate', dataLayer[c].context.searchParameters.checkinDate);
                            sessionStorage.setItem('checkoutDate', dataLayer[c].context.searchParameters.checkoutDate);
                            sessionStorage.setItem('nights', dataLayer[c].context.searchParameters.nightCount);
                        }
                    }
                }
                if (dataLayer[c].event == 'ProductAdded') {
                    var room_id = dataLayer[c].product.id;
                    var res_room = room_id.split('/R');
                    var totalPrice = dataLayer[c].context.searchParameters.nightCount * dataLayer[c].product.price;

                    sessionStorage.setItem('currency', dataLayer[c].product.currency);
                    sessionStorage.setItem('roomId', res_room[1]);
                    sessionStorage.setItem('roomName', dataLayer[c].product.name);
                    sessionStorage.setItem('totalPrice', totalPrice);
                }
                if (dataLayer[c].event == 'ViewBasket') {
                    var room_id = dataLayer[c].basket[0].id;
                    var res_room = room_id.split('/R');
                    var totalPrice = dataLayer[c].context.searchParameters.nightCount * dataLayer[c].basket[0].price;

                    sessionStorage.setItem('currency', dataLayer[c].basket[0].currency);
                    sessionStorage.setItem('roomId', res_room[1]);
                    sessionStorage.setItem('roomName', dataLayer[c].basket[0].name);
                    sessionStorage.setItem('totalPrice', totalPrice);
                }
            }

            if($('#title').val() !== undefined)
            sessionStorage.setItem('title', $('#title').val());
        
            if($('#firstname').val() !== undefined)
                sessionStorage.setItem('firstName', $('#firstname').val());

            if($('#lastname').val() !== undefined)
                sessionStorage.setItem('lastName', $('#lastname').val());
            
            if($('#email').val() !== undefined)
                sessionStorage.setItem('email', $('#email').val());
            
            if($('#phonenumber').val() !== undefined)
                sessionStorage.setItem('phoneNumber', $('#phonenumber').val());

            if($('#country1').val() !== undefined)
                sessionStorage.setItem('country', $('#country1').val());

            return true;
        } catch (err) {
        }
    }
    function trackingVisitor(){

        /*** Breowser and system data ***/
        /* Browser data */
        let browser_data = {
            'browser': navigator.appName || "Unknown browser",
            'version': navigator.userAgent || navigator.appVersion || "Unknown version",
            'os': navigator.platform || "Unknown OS",
            'cookies': (navigator.cookieEnabled === true ? 'Enabled' : 'Disabled') || "Unable to determine cookie status",
            'language': navigator.language || "Unknown language"
        };
        /* End Browser data */

        /** Geolocalisation **/

        let geolocData = {};
        var onSuccess = function(location){
            geolocData.latitude = location.location.latitude !== undefined ? location.location.latitude : '';
            geolocData.longitude = location.location.longitude !== undefined ? location.location.longitude : '';
            geolocData.country = location.country.names.fr !== undefined ? location.country.names.fr : '';
            geolocData.code_iso = location.country.iso_code !== undefined ? location.country.iso_code : '';
            geolocData.city = location.city.names.fr !== undefined ? location.city.names.fr : '';
           };
            
            var onError = function(error){
                console.log(
                    "Error:\n\n"
                    + JSON.stringify(error, undefined, 4)
                );
            };
            
            geoip2.city(onSuccess, onError);
        /** end Geolocalisation **/
        return {visitor: {browser: browser_data, geoloc: geolocData}};
    }
    function getTimeOnWebSite(){
        let timeNow = Math.round(Date.now() / 1000);
        let timeDiff = 0;

        if (getCookie("beginTime") == null) {
            setCookie('beginTime', timeNow);
        }else{
            let timeCal = timeNow-getCookie('beginTime');
            timeDiff = Math.abs(Math.round(timeCal));
        }
        return timeDiff;
    }
    function cleanArray(array) {
      var i, j, len = array.length, out = [], obj = {};
      for (i = 0; i < len; i++) {
        obj[array[i]] = 0;
      }
      for (j in obj) {
        out.push(j);
      }
      return out;
    }
    function getAnyVisitedPages(pagesV){
        
        var visite = document.location.toString();
        pagesV.push(visite);
        var clearArray = cleanArray(pagesV);
        var nbPages = clearArray.length;
        return nbPages;
    }
    function getCurrentPageaddress(){
        var currentPage = document.location.toString();
        return currentPage;
    }
    function fnGenerateUid(separator) {
        var delim = separator;

        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }

        var unique_id = (S4() + S4() + delim + S4() + delim + S4() + delim + S4() + delim + S4() + S4() + S4());
        return unique_id;
    }

}

export default trackResaData;