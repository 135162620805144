import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'babel-polyfill';
import App from './App';
import * as serviceWorker from './serviceWorker';

 //   hotel_ref="J2LI";
    let hdbChat = document.createElement("DIV");
    hdbChat.id = "hdb-chat";
    document.body.appendChild(hdbChat);
    ReactDOM.render(<App hotel_ref={hotel_ref}/>, document.getElementById('hdb-chat'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
// Check if hot reloading is enable. If it is, changes won't reload the page.
// This is related to webpack-dev-server and works on development only.

